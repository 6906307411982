<script setup lang="ts">
import type { ICart } from '@/types/types'
import { LocalStorageKey } from '@/types/enums'

defineProps({
  hideActions: {
    type: Boolean,
    default: false,
  },
})

const isOpen = ref<boolean>(true)

const cart = useLocalStorage(LocalStorageKey.Cart, {} as ICart)
</script>

<template>
  <div v-if="cart && cart?.address" class="pb-4 mb-4 shadow sm:rounded-lg px-4 py-2">
    <div class="flex items-center justify-between py-4">
      <h4 class="block text-xl font-bold text-primary-500">
        Endereço de cobrança
      </h4>

      <button
        v-if="!hideActions"
        aria-label="Botão para alternar entre aberto e fechado"
        type="button"
        @click="isOpen = !isOpen"
      >
        <Icon
          name="mdi:chevron-down"
          class="w-6 h-6 duration-300 text-primary-400"
          :class="isOpen ? 'rotate-180 duration-300' : ''"
        />
      </button>
    </div>

    <div class="relative">
      <div
        class="transition-all duration-500 ease-in-out overflow-hidden bg-white"
        :class="isOpen ? 'max-h-[100%] opacity-100' : 'max-h-0 opacity-0'"
      >
        <div class="flex items-center justify-between mb-1">
          <span class="block text-sm text-normal text-neutral-200">
            {{ cart.address?.addressOne }}, {{ cart.address?.number }} - {{ cart.address?.addressTwo }}
          </span>
          <NuxtLink
            v-if="!hideActions"
            class="text-primary-500 font-semibold text-xs cursor-pointer"
            to="/checkout/endereco-de-cobranca"
            no-prefetch
          >
            Alterar
          </NuxtLink>
        </div>

        <span class="block text-sm text-normal text-neutral-200 mb-1">
          {{ cart.address?.district }} - {{ cart.address?.city }} - {{ cart.address?.state }}
        </span>
        <span class="block text-sm text-normal text-neutral-200">
          {{ formatZipCode(cart.address?.zip, { mask: true }) }}
        </span>
      </div>
    </div>
  </div>
</template>
